import { useNavigate } from "react-router-dom";
import "./styles/window.css";
import ibillslogo from "./assets/ibillslogo.png"
import {
    FaUser,
    FaHeadset,
    FaRegIdBadge,
    FaArrowDownUpAcrossLine,
    FaBell,
    FaChevronDown,
    FaBorderAll,
    FaChartColumn,
    FaGift,
    FaFile,
    FaAddressBook,
    FaBuffer,
    FaFileCircleXmark,
    FaStore,
    FaShare,
    FaGear
} from "react-icons/fa6";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authState, setPacketStatus, setMessage, resetAuthState } from '../shared/slices/authSlice';
import HandleSubmit from "./components/HandleSubmit";
import { env } from "../shared/environment/envSelector";
import { access } from "fs";

type Props = {
    currentView: any
    activeId: number
}

const Window = (props: Props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [profileDropDownOptions, setProfileDropDownOptions] = useState<boolean>(false)
    const [statusMessage, setStatusMessage] = useState<boolean>(false);
    const { packetStatus, message, firstName, lastName, phoneNo, password, email, emailOtp, phoneOtp, getUser } = useSelector((state: { auth: authState }) => state.auth);
    const { logout } = env;
    const { access_token, user } = getUser
    const { first_name, last_name } = user

    const handleLogout = async (e: any) => {
        const success = await HandleSubmit(e, {}, logout, "DELETE", setPacketStatus, setStatusMessage, undefined, access_token);
        if (success) {
            dispatch(resetAuthState())
        }
    }


    return (
        <main>
            <div className='menu_bar'>
                <div>
                    <img src={ibillslogo} alt="ibills logo" />
                </div>
                <hr className='w_hr' />
                <ul>
                    <li
                        className={props.activeId === 1 ? "active" : "inactive"}
                        onClick={() => {
                            navigate("/merchant/dashboard")
                        }}
                    >
                        <FaBorderAll size={15} />
                        Dashboard
                    </li>
                    <li
                        className={props.activeId === 2 ? "active" : "inactive"}
                        onClick={() => {
                            navigate("/merchant/transactions")
                        }}
                    >
                        <FaChartColumn size={15} />
                        Transactions
                    </li>
                    <li
                        className={props.activeId === 3 ? "active" : "inactive"}
                        onClick={() => {
                            navigate("/merchant/customers")
                        }}
                    >
                        <FaRegIdBadge size={15} />
                        Customers
                    </li>
                    <li
                        className={props.activeId === 4 ? "active" : "inactive"}
                        onClick={() => {
                            navigate("/merchant/subscriptions")
                        }}
                    >
                        <FaBuffer />
                        Subscription Plans
                    </li>
                    <li
                        className={props.activeId === 5 ? "active" : "inactive"}
                        onClick={() => {
                            navigate("/merchant/subscribers")
                        }}
                    >
                        <FaAddressBook size={15} />
                        Subscribers
                    </li>
                    <li
                        className={props.activeId === 6 ? "active" : "inactive"}
                        onClick={() => {
                            navigate("/merchant/disputes")
                        }}
                    >
                        <FaFileCircleXmark size={15} />
                        Disputes
                    </li>
                    <li
                        className={props.activeId === 7 ? "active" : "inactive"}
                        onClick={() => {
                            navigate("/merchant/payouts")
                        }}
                    >
                        <FaShare size={15} />
                        Payouts
                    </li>
                    <li
                        className={props.activeId === 8 ? "active" : "inactive"}
                        onClick={() => {
                            navigate("/merchant/payment-request")
                        }}
                    >
                        <FaFile size={15} />
                        Payment Requests
                    </li>
                    <li
                        className={props.activeId === 15 ? "active" : "inactive"}
                        onClick={() => {
                            navigate("/merchant/payment-split")
                        }}
                    >
                        <FaArrowDownUpAcrossLine size={15} />
                        Payment Split
                    </li>
                    <hr className='w_hr' />
                </ul>
                <ul>SHOP
                    <li
                        className={props.activeId === 9 ? "active" : "inactive"}
                        onClick={() => {
                            navigate("/merchant/my-shop")
                        }}
                    >
                        <FaStore size={15} />
                        My Shop
                    </li>
                    <li
                        className={props.activeId === 10 ? "active" : "inactive"}
                        onClick={() => {
                            navigate("/merchant/create-product")
                        }}
                    >Create Product</li>
                </ul>
                <ul>MY REWARDS
                    <li
                        className={props.activeId === 11 ? "active" : "inactive"}
                        onClick={() => {
                            navigate("/merchant/my-rewards")
                        }}
                    >
                        <FaGift size={15} />
                        My Rewards
                    </li>
                </ul>
                <ul>SETTINGS
                    <li
                        className={props.activeId === 12 ? "active" : "inactive"}
                        onClick={() => {
                            navigate("merchant/business-verification2")
                        }}
                    >
                        <FaGear size={15} />
                        Integration Settings
                    </li>
                    <li
                        className={props.activeId === 18 ? "active" : "inactive"}
                        onClick={() => {
                            navigate("/merchant/api-and-web-hook")
                        }}
                    >
                        API & Web Hook
                    </li>
                </ul>
                <ul>SECURITY
                    <li
                        className={props.activeId === 17 ? "active" : "inactive"}
                        onClick={() => {
                            navigate("/merchant/security/access-control")
                        }}
                    >
                        <FaGear size={15} />
                        Access Control
                    </li>
                    <li
                        className={props.activeId === 16 ? "active" : "inactive"}
                        onClick={() => {
                            navigate("/merchant/security/access-control/audit-log-details")
                        }}
                    >
                        Audit Log
                    </li>
                </ul>
            </div>
            <div className='window'>
                <div className='header'>
                    <section className='header_ln1'>
                        <p className='welcome_msg'>Hello</p>
                        <p className='users_name'>{first_name} {last_name}</p>
                    </section>
                    <section className='header_ln2'>
                        <div className='header_ln2_field1'>
                            <FaHeadset size={18} />
                            <p>Help</p>
                        </div>
                        <div className='header_ln2_field2'>
                            <FaBell size={18} />
                            <p>Notifications</p>
                        </div>
                        <div className='header_ln2_field3'>
                            <FaUser size={18} />
                        </div>
                        <div className='header_ln2_field4'>
                            <FaChevronDown size={0} onClick={() => setProfileDropDownOptions(!profileDropDownOptions)} />
                            <ul className={profileDropDownOptions ? "profile_dropdown_opts_open" : "profile_dropdown_opts_close"}>
                                <li><a href="/Merchant/profile">Profile</a></li>
                                <li><a href="&">Settings</a></li>
                                <li><a onClick={handleLogout}>Logout</a></li>
                            </ul>
                        </div>
                    </section>
                </div>
                <div className='view_screen_container'>
                    {props.currentView}

                </div>
            </div>

        </main>
    )
}

export default Window